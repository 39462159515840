@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl sm:text-6xl font-noto font-light text-mred;
    /* @apply xl:inline */
    /* @apply block */
  }
  h2 {
    @apply text-4xl mt-5 font-noto font-light text-gray-900;
  }
  h3 {
    @apply text-4xl mt-6 font-noto font-light uppercase text-white bg-mred inline p-2 leading-loose text-gray-900;
  }
  h4 {
    @apply font-noto font-semibold text-xl pb-3 text-gray-900;
  }
  p {
    @apply font-noto font-normal text-black pb-5 text-gray-900;
  }
  a {
    @apply text-mred font-noto text-gray-700 ;
  }
}
